<template>
    <div>
      <h1>Contact Us Page</h1>
      <p>{{ message }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        message: ''
      };
    },
    created() {
       
    }
  };
  </script>
  