<template>
  <div class="home">

    <!-- Input and Button in the template -->
    <div class="row justify-content-center mb-3 sub-nav">
      <div class="col-md-6 d-flex align-items-center justify-content-end">
        <label for="searchInput" class="label-font-default">pyast.com/</label>
        <input type="text" id="searchInput" @input="validateInput" @keyup.enter="onEnter" v-model="searchCode"
          class="form-control me-2" style="max-width: 80px;" placeholder="Enter code">
        <button class="btn btn-primary btn-sm" @click="goToUrl">Go</button>
      </div>
      <div class="col-md-6 align-items-center d-flex justify-content-left">
        <span class="ms-2 text-secondary">Page erase in <span class="blinking-timer">{{ formattedTime }}</span> <button
            class="btn btn-primary add-time btn-sm" @click="addTime">Add Time</button></span>
      </div>
    </div>

    <div class="row mt-2">
      <!-- Drag-and-Drop and File Browser -->
      <div class="col-md-6">
        <div class="drop-zone" @dragover.prevent @drop.prevent="handleDrop" @click="triggerFileInput">
          <span class="material-icons-outlined upload-icon"> file_upload </span>
          <h3 for="textarea" class="dynamic-message"> Drag & drop files here </h3>
          <label class="label"> or
            <span class="browse-files">
              <input ref="fileInput" type="file" accept="image/*" style="display: none;" @change="handleFileUpload" />
              <span class="browse-files-text">browse file</span>
              <span>from device</span>
            </span>
          </label>
          <!-- Progress Overlay -->
          <div v-if="isUploading" class="progress-overlay">
            <div class="progress-container">
              <div class="progress-bar" :style="{ width: uploadProgress + '%' }"></div>
              <span class="text-white">{{ uploadProgress }}% Uploading..</span>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-6 text-center">
        <!-- <label for="textarea" class="form-label">Text Area</label> -->
        <textarea id="textarea" v-model="textAreaContent" class="form-control" placeholder="Copy & paste text here"
          rows="5"></textarea>
        <div class="d-flex gap-2 justify-content-center action">
          <div class="bg-white gap-2 b-action">
            <button class="btn btn-primary btn-sm" @click="pasteText">Paste</button>
            <button class="btn btn-warning btn-sm ms-2" @click="clearText">Clear</button>
            <button class="btn btn-success btn-sm ms-2" @click="saveText">Save</button>
          </div>
        </div>
      </div>

    </div>


    <div class="file-list" v-if="files.length">
      <!-- Loop through files array to dynamically create file cards -->
      <div class="file-card" v-for="file in files" :key="file.file_name">
        <!-- If the file is an image, show the image preview; otherwise, show a placeholder icon -->
        <img v-if="file.isImage" :src="file.fullPath" :alt="file.file_name" class="file-preview" />
        <img v-else src="../assets/images/file_temp.png" :alt="file.file_name" class="file-preview" />
        <div class="file-info">
          <!-- File name -->
          <p class="file-name">{{ file.file_name }}</p>
          <div class="file-actions">
            <!-- Download button -->
            <a :href="file.fullPath" download :title="file.name">
              <button class="download-btn"><i class="bi bi-download"></i></button>
            </a>
            <!-- Delete button -->
            <button class="download-btn" @click="moveFile(file)">
              <img src="@/assets/images/move_file_icon.svg" alt="Download" style="width: 20px; height: 20px;">
            </button>
            <button  v-if="file.isImage" class="download-btn" @click="previewFile(file)"><i class="bi bi-eye"></i></button>
            <button class="delete-btn" @click="deleteFile(file)"><i class="bi bi-trash3-fill"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-list">
      <div class="card" v-for="item in items" :key="item.id">
        <div class="card-content">
          <p class="card-text">{{ item.text }}</p>
        </div>
        <div class="card-actions">
          <button :class="['copy-btn', { 'copied': isCopied }]" @click="copyText(item.text, item.id)">
            <i class="bi bi-copy"></i>
          </button>
          <button class="download-btn" @click="previewText(item.text)"><i class="bi bi-eye"></i></button>
          <button class="delete-btn" @click="deleteItem(item.id)">
            <i class="bi bi-trash3-fill"></i>
          </button>
        </div>
        <!-- Notification message -->
        <div v-if="isCopied && copiedItemId === item.id" class="notification">
          Text copied successfully!
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="extendTimeModal" tabindex="-1" aria-labelledby="extendTimeModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="extendTimeModalLabel">Extend Expiration Time</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form id="extendTimeForm">
              <div class="mb-3">
                <label for="timeSelect" class="form-label">Select Time Extension</label>
                <select class="form-select" id="timeSelect" @change="calculateCost">
                  <option value="1">12 days = $1.00</option>
                  <option value="2.40">30 days = $2.40</option>
                  <option value="7.20">3 months = $7.20</option>
                  <option value="14.60">6 months = $14.60</option>
                  <option value="29.20">1 year = $29.20</option>
                </select>
              </div>
              <!-- <div class="mb-3">
              <label for="totalCost" class="form-label">Total Cost</label>
              <input type="text" class="form-control" id="totalCost" readonly>
            </div> -->
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="submitTimeExtension">Submit</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Move Modal -->
    <div class="modal fade" id="moveFileModal" tabindex="-1" aria-labelledby="moveFileModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content shadow-lg rounded-lg">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="extendTimeModalLabel">Put File to Another Page</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h6 class="fw-bold">Put in another page</h6>
            <div class="d-flex align-items-center mb-4">
              <label for="putInput" class="label-font-default me-2">pyast.com/</label>
              <input type="text" id="putInput"  @input="validatePutInput" v-model="putFileCode"
                  class="form-control me-2" style="max-width: 120px;" placeholder="Enter code">
              <button class="btn btn-success btn-sm" @click="putFile(selectedMoveFile)">Put</button>
            </div>
            
            <div v-if="isPutRequest" class="alert alert-success text-center" id="successMessage">
              <strong>Success!</strong> File put to <a  :href="putSuccessURl" class="text-decoration-underline">{{putSuccessURl}}</a>.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="errorModalLabel">Opps!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

            </button>
          </div>
          <div class="modal-body">
            {{ errorMessage }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for image preview -->
    <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Preview</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body text-center" v-if="imagePreviewUrl">
                <img v-if="imagePreviewUrl" :src="imagePreviewUrl" alt="Image Preview" class="img-fluid" />
              </div>
              <div v-else>
                <textarea v-model="textPreview" class="form-control p-3" style="height: 20vh;"></textarea>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
    </div>

</template>
  
<script>
import api from '../api.js';

export default {
  data() {
    return {
      textAreaContent: '',
      clipboardContent: '',
      image: null,
      progress: 0,
      message: '',
      fileData: null,   // Holds the file data (base64 or URL)
      fileName: null,   // Name of the uploaded file
      isImage: false,   // Flag to check if the file is an image
      files: [], // List of uploaded files
      uploadProgress: 0, // Track upload progress percentage
      items: [],
      uuid: '',
      searchCode: '',
      errorMessage: '',
      remainingTime: 30,
      timer: null,
      isCopied: false,
      copiedItemId: null,
      isUploading: false,
      selectedMoveFile: null,
      putFileCode : "",
      isPutRequest : false,
      putSuccessURl: "",
      imagePreviewUrl: '',
      textPreview: '',

    };
  },
  props: ['paramValue'],
  async mounted() {
    var a = "";
    if (this.paramValue) {
      // Regular expression to allow only numbers and letters (uppercase and lowercase)
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(this.paramValue)) {
        a = this.paramValue.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        a = this.paramValue;
      }
    }
    if (a != "") {
      this.uuid = a;
    }
    await this.initialCheck();
    await this.fetchFiles();
  },
  computed: {
    formattedTime() {
      // const minutes = Math.floor(this.remainingTime / 60);
      // const seconds = this.remainingTime % 60;
      // return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
      const hours = Math.floor(this.remainingTime / 3600);
      const minutes = Math.floor((this.remainingTime % 3600) / 60);
      const seconds = this.remainingTime % 60;

      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;

    },
  },
  methods: {
    async initialCheck() {
      try {
        const response = await api.get(`initial_check.php?a=${this.uuid}`);
        const uuid = response.data.a;
        const rTime = response.data.remainingTime;
        // this.$router.push({ query: { a: uuid } });
        this.$router.replace({ path: uuid });
        this.searchCode = uuid
        this.uuid = uuid
        this.remainingTime = rTime
        // console.log("complete init");
        this.startTimer();
      } catch (error) {
        this.error = "Failed to fetch data: " + error.message;
      } finally {
        this.loading = false;
      }
    },
    async fetchFiles() {
      console.log("fetc", this.uuid);
      try {
        const response = await api.get('fetch_data.php', {
          params: { 'uuid_code': this.uuid }
        });

        // Check the response from the server
        if (!response.data.success) {
          // Error from PHP (custom error handling)
          console.error('Error:', response.data.message);
          this.errorMessage = response.data.message;
          $('#errorModal').modal('show');
        } else {
          var data = response.data.data;
          // Filter text content
          const textArray = data.filter(item => item.type === "text");
          this.items = textArray;

          // Filter file content
          const fileArray = data.filter(item => item.type === "file" || item.type === "image");
          this.files = fileArray;

        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    padZero(number) {
      return number.toString().padStart(2, '0');
    },
    // Upload file to the server
    async uploadFile(file) {
      console.log("start - uploadFile");
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uuid_code', this.uuid)
      this.isUploading = true;
      try {
        const response = await api.post('upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

          onUploadProgress: (progressEvent) => {


            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(progressEvent.loaded);
          },
        });
        console.log("progressEvent - finished");
        this.isUploading = false;
        this.uploadProgress = 0;
        // Check the response from the server
        if (response.data.success) {
          // After successful upload, update the file URL
          const fileName = response.data.fileName;
          const fullPath = response.data.fullPath;
          const uploadID = response.data.upload_id;
          const isImage = response.data.isImage;
          const type = response.data.isImage ? "image":"file";
          this.files.push({
            file_name: fileName,
            isImage: isImage,
            fullPath: fullPath, // URL will be set after upload
            id: uploadID,
            type: type
          });
          console.log("upload - finished");
        } else {
          this.errorMessage = "Code not found!";
          $('#errorModal').modal('show');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
        this.isUploading = false;
        this.uploadProgress = 0;
        alert('Error uploading file:', error);

      } finally {
        this.uploadProgress = 0;
      }
    },
    addTime() {
      $('#extendTimeModal').modal('show');
    },
    async handleGenerateNew() {
      try {
        const response = await api.get(`initial_check.php?new=true`);
        const uuid = response.data.a;
        this.$router.push({ query: { a: uuid } });
        this.searchCode = uuid
        this.uuid = uuid
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    },
    copyText(textContent, id) {
      // Copy text to clipboard using the modern Clipboard API
      navigator.clipboard.writeText(textContent).then(() => {
        this.copySuccess = "Text copied to clipboard!";
        this.isCopied = true;  // Trigger animation

        this.copiedItemId = id;
        // Hide notification after 2 seconds
        setTimeout(() => {
          this.isCopied = false;
          this.copiedItemId = null;
        }, 2000);

      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    },
    goToUrl() {
      // If the input field has a value, update the URL with ?a= parameter and reload
      if (this.searchCode) {
        this.$router.replace({ path: this.searchCode });
        setTimeout(() => {
          const newUrl = `${window.location.pathname}`;
          window.location.href = newUrl; // Reloads the page with the new URL
        }, 500); // 2000 milliseconds = 2 seconds

      }
    },
    onEnter() {
      this.goToUrl()
    },
    validateInput(event) {
      const input = event.target.value;
      // Regular expression to allow only numbers and letters (uppercase and lowercase)
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(input)) {
        // Replace input value with the valid part only (removes special characters)
        this.searchCode = input.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        // If input is valid, update the value
        this.searchCode = input;
      }
    },
    validatePutInput(event) {
      const input = event.target.value;
      // Regular expression to allow only numbers and letters (uppercase and lowercase)
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(input)) {
        // Replace input value with the valid part only (removes special characters)
        this.putFileCode = input.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        // If input is valid, update the value
        this.putFileCode = input;
      }
    },

    // Drag-and-drop file
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.processFile(file);
      }
    },
    async saveText() {
      try {
        var formData = new FormData();
        formData.append('save_text', this.textAreaContent)
        formData.append('uuid_code', this.uuid)
        const response = await api.post('text_save.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.success) {
          this.items.push({
            file_name: "",
            text: response.data.text,
            isImage: response.data.isImage,
            fullPath: "", // URL will be set after upload
            id: response.data.upload_id,
          });
          // alert('Text saved successfully!');
          this.clearText(); // Optional: Clear the text area after saving
        } else {
          alert('Error saving text.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while saving the text.');
      }
    },
    // Trigger file input when clicked
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    // Process the uploaded file (handles both images and non-images)
    processFile(file) {
      this.fileName = file.name;
      this.isImage = file.type.startsWith("image/");

      const reader = new FileReader();

      // Handle image files
      if (this.isImage) {
        reader.onload = (event) => {
          this.fileData = event.target.result;  // Base64 image URL
 
        };
        reader.readAsDataURL(file);
      }
      // Handle non-image files
      else {
        reader.onload = (event) => {
          const blob = new Blob([event.target.result], { type: file.type });
          this.fileData = URL.createObjectURL(blob);  // Create a URL for the file
           
        };
        reader.readAsArrayBuffer(file);
      }
      // Upload file to server
      this.uploadFile(file);
    },

    // Delete file
    async deleteFile(file) {

      try {
        await api.delete(`delete.php?file=${encodeURIComponent(file.file_name)}&id=${encodeURIComponent(file.id)}`);
        this.files = this.files.filter(f => f.file_name !== file.file_name);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
    async deleteItem(id) {
      try {
        await api.delete(`delete_record.php?id=${encodeURIComponent(id)}`);
        this.items = this.items.filter(f => f.id !== id);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
    moveFile(file) {
      this.selectedMoveFile = file;
      this.isPutRequest = false;
      this.putFileCode = "";
      $('#moveFileModal').modal('show');
    },
    async putFile(file){
      if(this.putFileCode != ""){
        try {
        const response = await api.get('put_file.php', {
          params: { 'file_name': file.file_name, 'uuid_code': this.putFileCode, 'type': file.type }
        });

        // Check the response from the server
        if (response.data.success) {
          // Error from PHP (custom error handling)
          this.isPutRequest = true;
          const host = window.location.hostname;
          
          // Example logic to set dynamic URL based on environment
          if (host === 'localhost') {
            this.putSuccessURl = `http://localhost:5173/${this.putFileCode}`; // Local development URL
          } else {
            this.putSuccessURl = `https://${host}/${this.putFileCode}`; // Production URL
          } 
         
        } else {
          var data = response.data.data;
          // Filter text content
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
      }


    },
    previewFile(file) {
      this.imagePreviewUrl = file.fullPath;
      $('#imageModal').modal('show');
    },
    previewText(text) {
      this.imagePreviewUrl = null;
      this.textPreview = text;
      $('#imageModal').modal('show');
    },

    // Handle file upload (Browse file)
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.processFile(file);
      }
    },

    pasteImage() {
      // Listen for paste events on the document
      console.log('Paste Image button clicked');
      document.addEventListener('paste', (event) => this.handlePaste(event));
    },
    async getClipboardImage() {
      try {
        // Access the clipboard data
        const clipboardItems = await navigator.clipboard.read();

        // Loop through clipboard items to find an image
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            // If the clipboard contains an image
            if (type.startsWith("image/")) {
              const blob = await clipboardItem.getType(type); // Get the image as a blob

              // Convert the blob to a base64 data URL for display
              const reader = new FileReader();
              reader.onload = (event) => {
                this.image = event.target.result; // Set the image data to display
              };
              reader.readAsDataURL(blob); // Convert the blob into a base64 data URL
              return; // Stop once we find the image
            }
          }
        }

        // If no image is found
        console.log("No image found in the clipboard.");
      } catch (error) {
        console.error("Error reading clipboard data:", error);
      }
    },
    handlePaste(event) {
      console.log("handlePaste");
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let item of items) {
        if (item.type.indexOf('image') !== -1) {
          const blob = item.getAsFile();
          this.image = URL.createObjectURL(blob); // Show the pasted image
          this.fileToUpload = blob; // Prepare the image for upload
        }
      }
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('file', this.fileToUpload);

      api.post('upload.php', formData, {
        onUploadProgress: progressEvent => {
          this.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      }).then(response => {
        this.message = 'Image uploaded successfully!';
      }).catch(error => {
        this.message = 'Error uploading image!';
      });
    },
    // Handle paste button
    pasteText() {
      navigator.clipboard.readText()
        .then(text => {
          this.clipboardContent = text;
          this.textAreaContent = text;
          // alert('Text copied from clipboard: ' + text);
        })
        .catch(err => {
          console.error('Failed to read clipboard: ', err);
        });
    },

    // Handle paste and insert button
    clearText() {
      this.textAreaContent = '';
    }
  }

};
</script>

<style scoped>
.blinking-timer {
  /* animation: blink 1s step-start infinite; */
  color: #df2b2b;
  /* Change to your desired color */
  font-weight: bold;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form-control {
  padding: .375rem 0.05rem;
}

#textarea {
  border: 2px dashed #7b2cbf;
  border-radius: 15px;
  padding-left: inherit;
  height: 170px;
}

.form-control:focus {
  box-shadow: unset !important;
}

.notification {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  animation: slideDownUp 2s ease-in-out forwards;

}

@keyframes slideDownUp {
  0% {
    transform: translateY(-100%);
  }

  10% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(90, 128, 179, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.progress-container {
  text-align: left;
  font-size: 18px;
}

.progress-bar {
  background-color: white;
  height: 20px;
  width: 0;
  transition: width 0.5s ease;
}

.action {
  margin-top: -30px;
}

.action .btn {
  width: 67px !important;
}

.action .b-action {
  width: 237px !important;
}

@media (max-width: 768px) {
  #textarea {
    margin-top: 5px;
  }

  .file-list {
    justify-content: center;
  }

  .card-list {
    justify-content: center;
  }

  .sub-nav .col-md-6 {
    justify-content: center !important;
  }
  .img-fluid {
  max-width: 100%;
  height: auto;
}
}</style>
