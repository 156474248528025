<template>

<div class="container">
        <!-- Header Section -->
        <div class="text-center">
            <h4>About PYAST</h4>
        </div>

        <!-- Content Section -->
        <div class="p-4">
            <p><strong>"PYAST" (pronounced "p-yay`-st")</strong> is a shorthand mnemonic acronym formed by combining parts of each word from the common computer action "Copy-Paste".</p>
            <p>With PYAST, you can transfer any files between any devices for free. Think of PYAST as a device bridge, allowing you to move files and text where you need it instantly and free (e.g., phone to laptop, desktop to tablet, etc.).</p>
            <p>But… PYAST is more than a simple file-sharing platform. All uploaded files are “hidden in plain sight” using random page urls that expire in 24 hours. Pyast has advanced features too.  All files are accessible to anyone with the url and does not require user accounts to be set up or to be logged into. However a personal PIN can be added to protect access to a page.</p>
            
            <!-- Features Section -->
            <h5 class="mt-4">Pyast Advanced Features:</h5>
            <ol>
                <li>Choose your own URL/page name and leave it open to whoever you give the URL, or you can add a PIN to limit access to only those with the PIN.</li>
                <li>You can add time/life to your page from 12 days (for $1) to 1 year (for $29.20).</li>
            
            <!-- Pricing Section -->
            <div class="mt-1">
                <ul>
                    <li>12 days = $1</li>
                    <li>30 days = $2.40</li>
                    <li>3 months = $7.20</li>
                    <li>6 months = $14.60</li>
                    <li>1 year = $29.20</li>
                </ul>
                <p>If you prefer not to buy time, but want to add security to the your (24 hour/non PIN) page then we suggest that you make the page name longer just as you would choose a password: (i.e. “Timfilesx5q29vb”)
In this example your url would be <code>pyast.com/timfilesx5q29vb</code>.</p>
            </div>
            <li>You can copy a file or text from one page/url to another instantly, you can choose the url/name of this page, allowing you to share only those files you wish on the new url. </li>
          </ol>
            <!-- Best Practices Section -->
            <div class="highlight">
                <h5>Some notes about best use practices:</h5>
                <p>If you post your page url online (where bots can read and index your url), the natural anonymity of your randomly generated url/page name may not protect your files from visitors. Some BOTs do not obey general robots protocol as stated on Pyast.com and if your url is public may find and index your page and the files on it.  To prevent this, some users (who accidentally posted a url publicly) choose to move their files to a new page, or simply buy more time for the page, which then gives you the option to add a PIN to the page. With a page PIN no one but those you share the PIN with will be able to read your page or the files on it.</p>
            </div>

            <!-- Closing Section -->
            <p class="mt-4 footer-text">Thanks for using Pyast.com!</p>
        </div>
    </div>

</template>
  
<script>
import api from '../api.js';

export default {
  data() {
    return {

    };
  },
  props: ['paramValue'],
  async mounted() {

  },
  computed: {
    
  },
  methods: {
    async initialCheck() {
      try {
        const response = await api.get(`initial_check.php?a=${this.uuid}`);
        const uuid = response.data.a;
        const rTime = response.data.remainingTime;
        // this.$router.push({ query: { a: uuid } });
        this.$router.replace({ path: uuid });
        this.searchCode = uuid
        this.uuid = uuid
        this.remainingTime = rTime
        // console.log("complete init");
        this.startTimer();
      } catch (error) {
        this.error = "Failed to fetch data: " + error.message;
      } finally {
        this.loading = false;
      }
    },
    
    
  }

};
</script>

<style scoped>
          body {
            background-color: #f8f9fa;
            font-family: Arial, sans-serif;
        }
        .container {
            background-color: #ffffff;
            border-radius: 10px;
            padding: 30px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            
        }
        h4 {
            font-size: 2rem;
            font-weight: 600;
            color: #343a40;
        }
        h5 {
            font-size: 1.25rem;
            font-weight: 500;
            color: #007bff;
        }
        ul {
            margin-left: 20px;
        }
        p {
            line-height: 1.6;
            color: #6c757d;
        }
        code {
            background-color: #e9ecef;
            padding: 2px 4px;
            border-radius: 4px;
            font-size: 0.9rem;
        }
        .highlight {
            background-color: #f8d7da;
            padding: 15px;
            border-radius: 8px;
            border-left: 4px solid #dc3545;
            margin-bottom: 15px;
        }
        .footer-text {
            font-weight: bold;
            font-size: 1.1rem;
            color: #495057;
        }
        p{
          color: unset !important;
        }
</style>
